body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #a4d5e0;
  overflow: hidden;
  touch-action: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
video {
  display:block;
}
:root {
  --amplify-primary-color: #35CCA9;
  --amplify-primary-tint: #78959B;
  --amplify-primary-shade: #78959B;
  --amplify-grey:#78959B;
}

amplify-sign-in,amplify-sign-up,amplify-confirm-sign-up,amplify-forgot-password {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
}

.desktop-capturer-selection {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(30,30,30,.75);
  color: #fff;
  z-index: 10000000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.desktop-capturer-selection__scroller {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
}
.desktop-capturer-selection__list {
  max-width: calc(100% - 100px);
  margin: 50px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  overflow: hidden;
  justify-content: center;
}
.desktop-capturer-selection__item {
  display: flex;
  margin: 4px;
}
.desktop-capturer-selection__btn {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 145px;
  margin: 0;
  border: 0;
  border-radius: 3px;
  padding: 4px;
  background: #252626;
  text-align: left;
  transition: background-color .15s, box-shadow .15s;
}
.desktop-capturer-selection__btn:hover,
.desktop-capturer-selection__btn:focus {
  background: rgba(98,100,167,.8);
}
.desktop-capturer-selection__thumbnail {
  width: 100%;
  height: 81px;
  object-fit: cover;
}
.desktop-capturer-selection__name {
  margin: 6px 0 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}