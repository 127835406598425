
#videoForDisplay,#canvas,#dataImage {
  position: absolute; 
  z-index: 10;
  width: 100%; 
  background:transparent;
  touch-action: none;
}
.FadeToInvisible {
  -webkit-mask-image: linear-gradient(to bottom, rgba(255,255,255,1) calc(100% - 20px), rgba(0,0,0,0));
}
.Invisible{
  opacity: 0!important;
}
#dragBar{
  width: 100%;
  height: 30px;
  position: absolute;
  top: 0px;
  -webkit-user-select: none;
  -webkit-app-region: drag;
}
canvas{
  z-index: 20;
}
#dataImage{
  z-index: 19;
}
.App{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DesktopMessage{
  text-align: center;
  width: 100%;
  background-color: #a4d5e0;
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
}
.Menu{
  display: flex;
  flex-direction: column;
  margin: 30px 30px 0px 30px;
  background-color: #a4d5e0;
  z-index: 1000;
}
.Menu.Open{
  height: 100vh;
}
.MenuRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  background-color: #a4d5e0;
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
}
.MenuRow.right{
  justify-content: flex-end;
}
.MenuRow.right .IconAndText{
  margin-left: 30px;
}
.MenuText{
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.MenuRow .Row{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  
}
.MenuRow .Row:nth-of-type(2){
  margin-top: 5px;
}
.SubscribeLink a{
  color: white;
}
.MenuRow .Row *{
  margin-left:30px;
}
.MenuUser{
  flex-wrap: wrap;
  width: 100%;
}
.Menu .SubscribeButton{
  margin-bottom: 0px;
  padding: 10px 20px 10px 20px;
}

.MenuUser *:first-child{
  width:100%;
  text-align: right ;
  margin-bottom: 5px;
}
.PlayContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.PlayPauseContainer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  position: absolute; 
  bottom: 0;
}
.PlayPauseContainer *{
  margin: 20px;
  padding: 20px;
  fill: white;
  color: white;
  height: 80px;
  z-index: 200;
  width: 80px;
  border-radius: 60px;
  background-color: #a4d5e0;
  color: white;
  font-weight: bold;
  margin-bottom: 30px;
}
.MenuText{
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.MenuLanguage,.MenuUser,.SubscribeLink{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  
}
.SubscribeLink a{
  color: white;
}
.MenuLanguage *,.MenuUser *{
  margin-left:30px;
}
.MenuUser{
  flex-wrap: wrap;
  width: 100%;
}

.MenuUser *:first-child{
  width:100%;
  text-align: right ;
  margin-bottom: 5px;
}

.Logo{
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 66%;
}
.Logo img{
  width: auto;
  height: 63px;
}
.error{
  padding: 30px;
  font-size: larger;
  color: white;
  font-weight: bold;
}

.IconAndText>svg{
  height: 50px;
  width: 50px;
  margin-bottom: 5px;
}
.IconAndText{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  fill: #78959B;
  color: #78959B;
  font-weight: bold;
}
.IconAndText.Selected{
  fill: white;
  color: white;
  font-weight: bolder;
}
.IconAndText.Black{
  fill: #000;
  color: #000;

}

.SliderIcon.Left{
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.SliderIcon.Right{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.SliderIcon>svg{
  width: 40px;
  height:40px;
  fill: white;
}
.Slider{
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 60%;
  flex-grow: 1;
}
.Slider>input{
  width:100%;
}
.Subscribe{
  display: flex;
  flex-direction: column;
  align-content: space-around;
  align-items: center;
  padding: 20px;
  color: white;
  font-weight: bold;
  font-size: large;
  background-color: #a4d5e0;
}
.Offer{
  display: flex;
  flex-direction: column;
  align-content: space-around;
  align-items: center;
  border: white;
  border-style: solid;
  width: 90%;
}
.Offer div{
  padding-left: 20px;
  padding-right: 20px;
}
.Subscribe div {
  text-align: center;
  margin-top: 20px;
}
.Subscribe>div{
  display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items: center;
}

.OfferName{
  font-size: 44px;
}
.OfferPrice .Price{
  font-size: 66px;
  margin-top: 0px;
}
.OfferPrice .Period{
  margin-top: 0px;
}
.OfferDescription{
  color: #78949b;
}
.OfferDescription b{
  font-weight: 900;
  color: #5f767b;
}
.OfferMark{
  position: absolute;
  right: 5%;
  transform: rotate(30deg);
  background-color: #ef7c8f;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}
.OfferMark._2{
  background-color: #7777bf;
}
.SubscribeButton{
  background-color: #35CCA9;
  padding: 20px 40px 20px 40px;
  border-radius: 15px;
  margin-bottom: 20px;
}

.DarkScreen{
  z-index:  1000;
  background-color: black;
  position: fixed;
  left: -1000px;
  top: -1000px;
  width: 5000px;
  height: 5000px;
  display: none;
}
.Tutorial{
  display: flex;
  flex-direction: column;
}
.Tutorial video{
  height:50vh;
}
.Tutorial .MenuText{
  text-align: center;
}
.Tutorial .Row{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 30px;
}
.TutorialImage{
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.MenuRow .PlayContainer{
  margin-top: 30px;
}
.MenuRow .Row:nth-of-type(2){
  margin-top: 30px;
}
.Tutorial .Row *{
  margin-left: 0px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.laser {
  width: 20px;
  margin-left: -10px;
  background-color: #35CCA9;
  border-radius: 10px;
  height: 20px;
  margin-bottom: -20px;
  position: relative;
  box-shadow: 0 0 15px #35CCA9;
  z-index: 300;
  opacity: 0.5;
  animation: scanning 0.7s ease-in-out infinite alternate;
}

@keyframes scanning {
  100% {
    top: 0px;
  }
}

.InstallPrompt {
  width:100%;
  
  
  text-align: center;
  color: white;
  font-weight: bold;
}
.InstallPrompt.IOS{
  position: absolute;
  bottom: 20px;
}
.InstallPrompt.Android p{
  position: absolute;
  bottom: 0px;
  text-align: center;
  width:100%;
}

.InstallPrompt .downarrow{
  fill: white;
  height:40px;
  margin-bottom: -20px;
  margin-top: -10px;
}
.InstallPrompt .shareIcon{
  fill: white;
  height:20px;
  margin-left:5px;
  margin-right:5px;
  margin-bottom: -3px;
}
.WordReader{
  position: absolute;
  bottom: 200px;
  width: 100%;
  z-index: 500;
  font-family: opendyslexic, sans-serif;
  font-weight:normal;
  font-style:normal;
  color: black;
}
#singleWord {
	grid-area: singleWord;
	
	width: 100%;
	height: 100%;

	display: grid;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
	justify-items: center;
	background: white;
}
#singleWord div:nth-of-type(4),#singleWord div:nth-of-type(5),#singleWord div:nth-of-type(6){
  height: 80px;
  line-height: 80px;
}

#left {
	text-align: right;
  width: 100%;
  justify-self: right;
  width: 100%;
}
.MidMarker{
  background-color: #a4d5e0;
  height: 15px;
  width: 3px;

}

#middle {
	text-align: center;
  width: 100%;
}
.FitContainer{
  justify-self: left;
}
#right {
	text-align: left;
  width: 100%;
}

@font-face {
  font-family: opendyslexic;
  src:  url("fonts/OpenDyslexic-Regular.woff2") format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}